import React from "react";
import styled from "styled-components";

import CloseIcon from "@mui/icons-material/Close";

const CloseButtonContainer = styled.div`
  position: relative;

  ${'' /* position: relative; */};
  top: 0;
  right:0;
  cursor: pointer;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  align-self:flex-end;
  &:hover {
    background: #EBEAEA;
  }
`;

const CloseButton = ({ onClose }) => (
  <CloseButtonContainer onClick={onClose}>
    <CloseIcon style={{ fontSize: 32, color: "#A0A0A0" }} />
  </CloseButtonContainer>
);

export default CloseButton;
