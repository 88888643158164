import React from "react";
import Image from "next/image";
import styled from "styled-components";
import logo from "../assets/success.png";

const ModalWrapper = styled.div`
  background-color: #282828;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  height: 75%;
  border-radius: 5px;
  @media screen and (max-width: 768px) {
    width: 80%;
  } ;
`;
const Modal = styled.form`
  display: grid;
  grid-template-rows: 45% 25% 30%;
  width: 70%;
  height: 100%;
`;

const Header = styled.div`
  display: grid;
  grid-template-rows: 3fr 1fr;
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 100px;
  height: 100px;
  margin: auto;

  @media screen and (max-width: 768px) {
    width: 75px;
    height: 75px;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 700;
  font-size: 2.5vw;
  width: 100%;
  @media screen and (max-width: 768px) {
    font-size: 5vw;
  } ;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 100%;
  font-size: 0.9vw;
  color: white;
  text-align: center;
  color: #6c6c6c;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const Button = styled.button`
  width: 100%;
  height: 45%;
  background-color: #17849e;
  color: #a5d5f5;
  border: none;
  border-radius: 0.4vw;
  cursor: pointer;
  color: white;
  &:hover {
    background-color: #0072e5;
  }
  font-size: 1.2vw;
  @media screen and (max-width: 768px) {
    font-size: 3vw;
  }
`;

const Success = ({ handleNext, messageContent, ...props }) => {
  return (
    <ModalWrapper>
      <Modal onSubmit={(e) => { e.preventDefault(); handleNext(); return false; }}>
        <Header>
          <LogoWrapper>
            <Image src={logo} alt="Success Logo"></Image>
          </LogoWrapper>
          <HeaderContent>Success!</HeaderContent>
        </Header>
        <ContentWrapper>
          <Content>
            {messageContent}
          </Content>
        </ContentWrapper>
        <ButtonWrapper>
          <Button>Login to ad-free platform</Button>
        </ButtonWrapper>
      </Modal>
    </ModalWrapper>
  );
};

export default Success;
