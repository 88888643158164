import React, { useState } from "react";
import Image from "next/image";
import styled from "styled-components";
import metamask from "../public/metamask.svg";
import walletconnect from "../public/walletconnect.svg";
import { useWeb3React } from "@web3-react/core";
import { walletConnect, injected } from "../web3/connectors";
import CloseButton from "./CloseButton";
import ImpactTheoryLogo from "../assets/founders-key.png";

const OverlayWrapper = styled.div`
  background-color: black;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 0px;
  overflow-y: hidden;
  z-index: 11;
  @media screen and (min-width: 600px) {
    padding: 30px;
  }
`;

const Modal = styled.div`
  background-color: rgba(66, 65, 63);
  width: 45%;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  display: grid;
  grid-template-rows:1fr 1fr;
  font-family: "Roboto Condensed", sans-serif;
  ${"" /* border: 1px solid red; */}
  border-radius:12px;


`;

const WalletButtonTop = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 12px;
  padding: 16px 16px;
  cursor: pointer;
  user-select: none;
  ${"" /* &:hover {
    background-color: rgb(216, 216, 216);
  } */
  }
  background-color: rgba(66, 65, 63);
  @media screen and (max-width: 768px) {
    font-size: 5vw;
  } ;
`;
const WalletButton = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-top:  2px solid rgb(153, 151, 147);
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  padding: 16px 16px;
  cursor: pointer;
  user-select: none;
  ${"" /* &:hover {
    background-color: rgb(216, 216, 216);
  } */
  }
  background-color: rgba(66, 65, 63);
  @media screen and (max-width: 768px) {
    font-size: 5vw;
  } ;
`;

const WalletIcon = styled.div`
  width: 5vw;
  height: 5vh;
  display: flex;
  border-radius: 50%;
  overflow: visible;
  box-shadow: none;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    width: 8vw;
    height: 8vh;
  } ;
`;

const WalletName = styled.div`
  width: 100%;
  font-size: 2.4vw;
  font-weight: 700;
  margin-top: 0.5em;
  color: rgb(12, 12, 13);
  color: white;
  @media screen and (max-width: 768px) {
    font-size: 4.5vw;
  } ;
`;

const WalletCta = styled.div`
  width: 100%;
  font-size: 1vw;
  margin: 0.333em 0px;
  color: rgb(169, 169, 188);
  font-family: "Roboto Condensed", sans-serif;
  @media screen and (max-width: 768px) {
    font-size: 2vw;
  } ;
`;

const LogoWrapper = styled.div`
  background-color: black;
  position: absolute;
  left: 40px;
  top: 40px;
  height: 45px;
  width: 200px;
  overflow: visible;
`;


const WalletModal = ({ onClose, setShowWalletChoice }) => {
  const { activate } = useWeb3React();

  const connectMetaMask = async () => {
    await activate(injected, (e) => {
      console.error(e);
    });

    onClose();
  };

  const connectWalletConnect = async () => {
    await activate(walletConnect, (e) => {
      console.error(e);
      walletConnect.walletConnectProvider = undefined;
      throw e;
    })
      .then(() => onClose)
      .catch((e) => {
        console.error(e);
        walletConnect.walletConnectProvider = undefined;
        setShowWalletChoice(false);
      });
  };

  return (
    <OverlayWrapper>
      <LogoWrapper>
        <Image src={ImpactTheoryLogo} alt="FounderKey Logo"></Image>
      </LogoWrapper>
      <Modal>
        <WalletButtonTop onClick={connectMetaMask}>
          <CloseButton onClose={onClose} />
          <WalletIcon>
            <Image src={metamask} alt="MetaMask" />
          </WalletIcon>
          <WalletName>MetaMask</WalletName>
          <WalletCta>Connect to your MetaMask Wallet</WalletCta>
        </WalletButtonTop>

        <WalletButton onClick={connectWalletConnect}>
          <WalletIcon>
            <Image src={walletconnect} alt="WalletConnect" />
          </WalletIcon>
          <WalletName>WalletConnect</WalletName>
          <WalletCta>Scan with WalletConnect to connect</WalletCta>
        </WalletButton>
      </Modal>
    </OverlayWrapper>
  );
};

export default WalletModal;
