import React from "react";
import styled from "styled-components";

const ModalWrapper = styled.div`
  background-color: #282828;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 62%;
  height: 80%;
  border-radius: 5px;
  @media screen and (max-width: 768px) {
    width: 80%;
  } ;
`;

const Modal = styled.div`
  display: grid;
  grid-template-rows: 40% 25% 35%;
  width: 70%;
  height: 100%;
`;
const SignHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 700;
  font-size: 1.8vw;
  @media screen and (max-width: 768px) {
    font-size: 5vw;
  } ;
`;

const SignButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const SignButton = styled.div`
  border: none;
  border-radius: 5px;
  width: 100%;
  height: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #0fa6b8;
  font-size: 1vw;
  cursor: pointer;
  &:hover {
    background-color: #0072e5;
  }
  @media screen and (max-width: 768px) {
    font-size: 3vw;
  }
`;

const TermWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Term = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 0.9vw;
  text-align: center;
  color: lightgrey;
  @media screen and (max-width: 768px) {
    font-size: 1.8vw;
  } ;
`;

const Sign = ({ handleSign, ...props }) => {
  return (
    <ModalWrapper>
      <Modal>
        <SignHeader>Create Signature</SignHeader>
        <TermWrapper>
          <Term>
            Signing this message proves that you own the wallet and subsequently the Impact Theory Founders Key.
          </Term>
        </TermWrapper>
        <SignButtonWrapper>
          <SignButton onClick={handleSign}>Prove Ownership</SignButton>
        </SignButtonWrapper>
      </Modal>
    </ModalWrapper>
  );
};

export default Sign;
