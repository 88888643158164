import React from "react";
import styled from "styled-components";

const ModalWrapper = styled.div`
  background-color: #282828;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  height: 75%;
  border-radius: 5px;
  @media screen and (max-width: 768px) {
    width: 80%;
  } ;
`;
const Modal = styled.form`
  display: grid;
  grid-template-rows: 30% 30% 40%;
  width: 70%;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 700;
  font-size: 1.8vw;
  width: 100%;
  @media screen and (max-width: 768px) {
    font-size: 5vw;
  } ;
`;
const InputWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  width: 100%;
  margin: 0px;
`;
const Input = styled.input`
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #231f1f;
  border: 1px solid #231f1f;
  width: 100%;
  font-size: 1vw;
  padding: 10px 10px;
  &:focus {
    outline: none;
  }
  @media screen and (max-width: 768px) {
    font-size: 2.5vw;
  }
`;
const InputLabel = styled.div`
  text-align: left;
  font-size: 0.9vw;
  padding-top: 8px;
  color: #656363;
  @media screen and (max-width: 768px) {
    font-size: 1.5vw;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  padding-top: 20px;
  justify-content: center;
`;
const Button = styled.button`
  width: 100%;
  height: 45%;
  background-color: #17849e;
  color: #a5d5f5;
  border: none;
  border-radius: 0.4vw;
  cursor: pointer;
  color: white;
  &:hover {
    background-color: #0072e5;
  }
  font-size: 1.2vw;
  @media screen and (max-width: 768px) {
    font-size: 3vw;
  }
`;

const EmailProvider = ({ handleNext, ...props }) => {
  return (
    <ModalWrapper>
      <Modal onSubmit={handleNext}>
        <Header>Email Address</Header>
        <InputWrapper>
          <Input required type="email" placeholder="Email Address"></Input>
          <InputLabel>* Email is collected so we can send you the magic link to access our Impact Theory Content.</InputLabel>
        </InputWrapper>
        <ButtonWrapper>
          <Button type="submit">Next</Button>
        </ButtonWrapper>
      </Modal>
    </ModalWrapper>
  );
};

export default EmailProvider;
