import React, { useState, useRef } from "react";
import Image from "next/image";
import styled from "styled-components";
import metamask from "../public/metamask.svg";
import walletconnect from "../public/walletconnect.svg";
import { useWeb3React } from "@web3-react/core";
import { walletConnect, injected } from "../web3/connectors";
import CloseButton from "./CloseButton";

import ButtonUnstyled, {
  buttonUnstyledClasses,
} from "@mui/base/ButtonUnstyled";

const blue = {
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
};

const ModalWrapper = styled.div`
  background-color: #282828;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 62%;
  height: 75%;
  border-radius: 5px;
  ${"" /* border: 1px solid purple; */}
  @media screen and (max-width: 768px) {
    width: 80%;
  } ;
`;

const Modal = styled.div`
  display: grid;
  grid-template-rows: 25% 25% 25% 25%;
  width: 70%;
  height: 100%;
`;

const Term = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6c6c6c;
  font-size: 0.65vw;
  @media screen and (max-width: 768px) {
    font-size: 1.8vw;
  } ;
`;

const WalletHeader = styled.div`
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.8vw;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    font-size: 5vw;
  } ;
`;
const WalletButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  margin: 10px 0px;
  font-size: 1.1vw;
  background-color: #17849e;
  cursor: pointer;
  color: white;
  border: none;
  @media screen and (max-width: 768px) {
    font-size: 3vw;
  }
  &:hover {
    background-color: ${blue[600]};
  }

  &.${buttonUnstyledClasses.active} {
    background-color: ${blue[700]};
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1),
      0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }

  &:disabled {
    background-color: grey;
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
const CheckboxWrapper = styled.div`
  color: #6c6c6c;
  font-size: 0.8vw;
  height: 40%;
  width: 80%;
  @media screen and (max-width: 768px) {
    font-size: 1.8vw;
  } ;
`;

const ConnectWallet = ({ handleConnectWallet }) => {
  const [error, setError] = useState(false);
  const [disable, setDisable] = useState(true);
  const handleOnClick = (e) => {
    setError(!e.target.checked);
    if (e.target.checked) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  };
  const errorStyle = error ? "1px solid red" : "";
  return (
    <ModalWrapper>
      <Modal>
        {" "}
        <WalletHeader>Connect Wallet</WalletHeader>
        <WalletButton
          type="submit"
          disabled={disable}
          onClick={handleConnectWallet}
        >
          Connect Wallet
        </WalletButton>
        <Term>
          Your public wallet address and email will be collected by Impact Theory
          exclusively with your consent. Your information will be used to manage
          your access to impact Theory content and will remain strictly
          confidential.
        </Term>
        <CheckboxWrapper style={{ border: errorStyle }}>
          <input
            // ref={checkRef}
            value={error}
            onClick={handleOnClick}
            type="checkbox"
            name="termCheck"
          />
          <span> I agree to terms of use</span>
        </CheckboxWrapper>
      </Modal>
    </ModalWrapper>
  );
};

export default ConnectWallet;
