import React, { useState } from "react";
import Image from "next/image";
import styled from "styled-components";
import metamask from "../public/metamask.svg";
import walletconnect from "../public/walletconnect.svg";
import { useWeb3React } from "@web3-react/core";
import { walletConnect, injected } from "../web3/connectors";
import CloseButton from "./CloseButton";

const ModalWrapper = styled.div`
  background-color: #282828;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 65%;
  height: 75%;
  border-radius: 5px;
  @media screen and (max-width: 768px) {
    width: 80%;
  }
`;

const Modal = styled.div`
  display: grid;
  grid-template-rows: 50% 50%;
  width: 70%;
  height: 100%;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 700;
  font-size: 2.4vw;
  @media screen and (max-width: 768px) {
    font-size: 5vw;
  } ;
`;
const TermWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
const Term = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 0.9vw;
  text-align: center;
  color: lightgrey;
  @media screen and (max-width: 768px) {
    font-size: 1.8vw;
  } ;
`;

const CheckEmail = ({ handleSign, ...props }) => {
  return (
    <ModalWrapper>
      <Modal>
        <Header>Check Your Email</Header>
        <TermWrapper>
          <Term>
            You should receive an email from Impact Theory that contains a link
            to Impact Theory Content
          </Term>
        </TermWrapper>
      </Modal>
    </ModalWrapper>
  );
};

export default CheckEmail;
