import React, { useState } from "react";
import Image from "next/image";
import styled from "styled-components";
import metamask from "../public/metamask.svg";
import walletconnect from "../public/walletconnect.svg";
import { useWeb3React } from "@web3-react/core";
import { walletConnect, injected } from "../web3/connectors";
import CloseButton from "./CloseButton";
import { stepLabelClasses, stepperClasses } from "@mui/material";
const StepsIndicator = styled.div`
  width: 90%;
  background-color: black;
  display: grid;
  grid-template-rows: 30% 70%;
  margin: auto;
  height: 100%;
`;

const IndicatorContent = styled.div`
  color: white;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  width: 100%;
`;
const StepContent = styled.div`
  color: lightgrey;
  font-size: 1.1vw;
  display: flex;
  justify-content: center;
  align-items: end;
  @media screen and (max-width: 768px) {
    font-size: 2.4vw;
  } ;
`;
const IndicatorContainer = styled.div`
  width: 75%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
`;
const Indicator = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const Circle = styled.div`
  border: 3px solid white;
  width: 50px;
  height: 30px;
  border-radius: 50%;
  background-color: white;
  @media screen and (max-width: 768px) {
    width: 50px;
    height: 25px;
  } ;
`;
const Line1 = styled.div`
  border: 1px solid white;
  width: 43%;
`;
const Steps = ({ showEmailProvider, showSign, showConfirmation }) => {
  const border = showEmailProvider || showSign || showConfirmation ? "2px solid #1496ac" : "";
  const circle = showEmailProvider || showSign || showConfirmation ? "3px solid #1496ac" : "";
  const borderSecond = showSign || showConfirmation ? "2px solid #1496ac" : "";
  const circleSecond = showSign || showConfirmation ? "3px solid #1496ac" : "";
  const borderThird = showConfirmation ? "2px solid #1496ac" : "";
  const circleThird = showConfirmation ? "3px solid #1496ac" : "";
  const defaultCircle = "3px solid #1496ac";

  return (
    <StepsIndicator>
      <IndicatorContent>
        <StepContent>Connect Wallet</StepContent>
        <StepContent>Provide Email</StepContent>
        <StepContent>Create Signature</StepContent>
        <StepContent>Confirmation</StepContent>
      </IndicatorContent>
      <Indicator>
        <IndicatorContainer>
          <Circle style={{ border: defaultCircle }}></Circle>
          <Line1 style={{ border: border }}></Line1>
          <Circle style={{ border: circle }}></Circle>
          <Line1 style={{ border: borderSecond }}></Line1>
          <Circle style={{ border: circleSecond }}></Circle>
          <Line1 style={{ border: borderThird }}></Line1>
          <Circle style={{ border: circleThird }}></Circle>
        </IndicatorContainer>
      </Indicator>
    </StepsIndicator>
  );
};

export default Steps;
