import React, { useEffect } from "react";
import styled from "styled-components";
import lottie from "lottie-web";
import lottieFile from "../assets/77793-loading-animation.json"

const ModalWrapper = styled.div`
  background-color: #282828;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  height: 75%;
  border-radius: 5px;
  @media screen and (max-width: 768px) {
    width: 80%;
  } ;
`;
const Modal = styled.form`
  display: grid;
  grid-template-rows: 20% 60% 20%;
  width: 70%;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 700;
  font-size: 1.8vw;
  width: 100%;
  @media screen and (max-width: 768px) {
    font-size: 5vw;
  } ;
`;

const Loading = ({ handleNext, ...props }) => {

  useEffect(()=> {
    if(typeof window !== "undefined")
      lottie.loadAnimation({
        container: document.querySelector("#react-logo"),
        animationData: lottieFile
      });
  }, [])

  return (
    <ModalWrapper>
      <Modal onSubmit={handleNext}>
        <Header>Loading</Header>
        <div id="react-logo"/>
      </Modal>
    </ModalWrapper>
  );
};

export default Loading;
