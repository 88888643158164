import Head from "next/head";
import Image from "next/image";
import styles from "../styles/Home.module.css";
import { useWeb3React } from "@web3-react/core";
import { useState, useRef, useEffect } from "react";
import WalletModal from "../components/WalletModal";
import ConnectWallet from "../components/ConnectWallet";
import styled from "styled-components";
import logoImage from "../assets/founders-key.png";
import Steps from "../components/Steps";
import EmailProvider from "../components/EmailProvider";
import Sign from "../components/Sign";
import axios from "axios";
import Success from "../components/Success";
import Error from "../components/Error";
import CheckEmail from "../components/CheckEmail.jsx";
import Router from 'next/router'
import Loading from "../components/Loading";

const OverlayWrapper = styled.div`
  background-color: black;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 0px;
  overflow-y: hidden;
  z-index: 11;
  @media screen and (min-width: 600px) {
    padding: 30px;
  }
`;
const LogoWrapper = styled.div`
  background-color: black;
  position: absolute;
  left: 40px;
  top: 40px;
  height: 45px;
  width: 200px;
  overflow: visible;
`;
const WalletWrapper = styled.div`
  background-color: black;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${"" /* border: 1px solid green; */}
`;
const ContentWrapper = styled.div`
  width: 55%;
  height: 70vh;
  display: grid;
  grid-template-rows: 20% 80%;
  ${"" /* border: 1px solid red; */}
  @media screen and (max-width: 768px) {
    width: 90%;
  }
  @media screen and (max-width: 768px) {
  } ;
`;
export default function Home() {
  const [showWalletChoice, setShowWalletChoice] = useState(false);
  const [showConnectWallet, setShowConnectWallet] = useState(true);
  const [showEmailProvider, setShowEmailProvider] = useState(false);
  const [showSign, setShowSign] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [messageContent, setMessageContent] = useState("");
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const { account, deactivate, library } = useWeb3React();
  const handleConnectWallet = () => {
    if (!account) {
      setShowWalletChoice(true);
    } else {
      deactivate();
    }
    setShowConnectWallet(false);
    setShowEmailProvider(true);
  };

  const handleNext = (e) => {
    setShowEmailProvider(false);
    setShowSign(true);
    e.preventDefault();
    setEmail(e.target[0].value);
  };

  const handleSignMessage = async () => {
    setLoading(true);
    if (!library || !account) return;

    try {
      const nonceRaw = await axios
        .post("api/nonce", { address: account })
        .then((res) => res.data.nonce);

      const message = `Login to the Ad-Free platform\n\nsecurity: ${nonceRaw}`;

      const userSig = await library?.getSigner().signMessage(message);

      const status = await axios
        .post(`api/register`, { address: account, signature: userSig, email })
        .then((res) => res.status);

      //todo: Check different statuses to report back. Only 200 is ok.
      // 400, display error screen, with api message content.
      if (status) {
        setShowSign(false);
        setShowSuccess(true);
        setLoading(false);
      }
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        setMessageContent(error.response.data);
      }
      setShowSign(false);
      setShowError(true);
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <Head>
        <title>Impact Theory - Content Access Manager</title>
        <meta name="description" content="Impact Theory's Content Access manager. We collect your wallet information and grant access to ITFK perks!" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <main className={styles.main}>
        <OverlayWrapper>
          <LogoWrapper>
            <Image src={logoImage} alt="FounderKey Logo"></Image>
          </LogoWrapper>
          <ContentWrapper>
            <Steps
              showEmailProvider={showEmailProvider}
              showSign={showSign}
              showConfirmation={showSuccess || showError}
            />
            {<WalletWrapper>
              {loading && <Loading />}
              {!loading && showConnectWallet && !account && (
                <ConnectWallet handleConnectWallet={handleConnectWallet} />
              )}

              {!loading && showEmailProvider && <EmailProvider handleNext={handleNext} />}
              {!loading && showSign && <Sign handleSign={handleSignMessage} />}
              {!loading && showSuccess && <Success handleNext={
                () => Router.push("https://shows.founderskey.io/login")}
                messageContent={`Congratulations! You've unlocked access to our ad-free platform.`} />}
              {!loading && showError && <Error handleNext={() => {
                setShowSign(true);
                setShowError(false);
                setMessageContent("");
              }} messageContent={messageContent} />}
            </WalletWrapper>}
          </ContentWrapper>
        </OverlayWrapper>

        {/* <ConnectWallet /> */}
        {showWalletChoice && (
          <WalletModal
            onClose={() => {
              setShowWalletChoice(false);
            }}
            setShowWalletChoice
          />
        )}
      </main>

      <footer className={styles.footer}>
      </footer>
    </div>
  );
}
